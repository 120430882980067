import React, { useCallback } from 'react';
import Particles from "react-particles";
import { loadFull } from "tsparticles";

import './App.css';
import particlesOptions from "./particles.json";
import programmerLogo from './girl.png';

function App() {
    const particlesInit = useCallback(main => {
        loadFull(main);
    }, [])

    return (
        <div className="App">
            <Particles options={particlesOptions} init={particlesInit}/>
            <header className="App-header">
                <img src={programmerLogo} className="App-logo" alt="logo"/>
                <h1 className={'name'}>
                    Hey, I'm Nishanthini - Software Developer :)
                </h1>
                <h3 className={'focus'}>
                    👀 Currently Focusing on Competitive Programming and Web Development
                </h3>
                <a
                    className="App-link"
                    href="https://github.com/nishanthini28"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    GitHub
                </a>
                <a
                    className="App-link"
                    href="https://www.linkedin.com/in/nishanthini-p/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    LinkedIn
                </a>
            </header>
        </div>
    );
}

export default App;
